<template>
  <div>
    <Header v-if="$route.name === 'home'" />
    <router-view />
    <Footer :footerData="footerData" v-if="$route.name === 'home'" />
    <div v-if="waLink && $route.name === 'home'" class="whatsapp">
      <p v-if="textView">{{ $t("buttons.chat") }}</p>
      <a
        :href="waLink"
        @mouseleave="textView = false"
        @mousemove="textView = true"
        class="whatsapp__btn"
      >
        <img src="@/assets/images/svg/photo.svg" />
      </a>
    </div>
    <modalv2Vue @close="$store.commit('TOGGLE_MOBILE_NAV')" :open="toggleNav">
      <ModalNav />
    </modalv2Vue>
  </div>
</template>

<script>
import Footer from "./components/layouts/Footer.vue";
import Header from "./components/layouts/Header.vue";
import axios from "axios";
import modalv2Vue from "@/components/modal/modalv3.vue";
import ModalNav from "@/components/modal/modalMobileNavbar.vue";
import { mapGetters } from 'vuex';
export default {
  components: { Header, Footer, modalv2Vue, ModalNav },
  data() {
    return {
      modalText2: {
        cText: this.$t("publicOffer"),
        success: false,
      },
      textView: false,
      footerData: null,
      waLink: null,
      modalTextOpened: false
    };
  },
  created() {
    this.FETCH_FOOTER();
  },
  computed: {
    ...mapGetters({toggleNav: "GET_TOGGLE_NAV"})
  },  
  methods: {
    FETCH_FOOTER() {
      axios
        .get("V1/get-footer")
        .then((response) => {
          if (response.status === 200) {
            this.footerData = response.data.data;
            this.waLink = response.data.data.contact.whatsappLink;
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
.whatsapp {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  z-index: 33;
  gap: 20px;
  p {
    padding: 12px 20px;
    font-weight: 400;
    border-radius: 30px;
    background-color: #ffffff;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;
    box-shadow: 0px 4px 24px 0px rgba(33, 42, 55, 0.1);
  }
  &__btn {
    padding: 12px;
    border-radius: 50%;
    background-color: #31df76;
    transition: all 0.5s ease-in;
    &:hover {
      transform: scale(1.2);
      img {
        transition: all 0.5s ease-in;
        transform: scale(0.9);
      }
    }
    img {
      widows: 32px;
      height: 32px;
    }
  }
}
</style>
