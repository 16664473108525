<template>
  <div :class="open ? 'faq open' : 'faq'" @click="$emit('toggleOpen', index)">
    <div class="question f-24"><span v-html="faq.title"></span></div>
    <div class="answer f-18"><span v-html="faq.description"></span></div>
  </div>
</template>

<script>
export default {
  name: "FAQs",
  props: ["faq", "index", "open"],
};
</script>
<style lang="scss" scoped>
.faq {
  display: block;
  width: 100%;
  padding: 24px 0;
  border-top: 1px solid #f4f4f4;

  @media (max-width: 576px) {
    padding: 24px;
  }
  .answer {
    font-weight: 500;
    color: #000000;
  }
  .question {
   
    position: relative;
    transition: all 0.3s ease-in;
    display: flex;
    align-content: center;
    align-items: center;
    span {
      width: 90%;
      font-weight: 600;
    }
    &:hover,
    &:focus {
      // font-weight: 600 !important;
      // transition: all 0.3s ease-in;
      &::after {
        // background-color: #f5f6fa;
      }
    }
    &::after {
      content: url("@/assets/images/png/pluse.png");
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      justify-content: space-around;
      margin: 0;
      display: flex;
      align-items: flex-start;
      transition: all 0.3s ease-out;
    }
  }
  .open {
    .question {
      font-weight: 600 !important;
      transform: scale(1);
    }
  }
}

.faq.open .question::after {
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
  // content: url('@/assets/images/svg/ic_right_up.svg');
  // transition: 400ms;
  // color: #D3D3D3;
  // font-size: 110px;
  // font-weight: 200;
  // padding: 9px 7px 7px 5px;
  // margin-right: 62px;
  // position: absolute;
  // transition: all 0.3s ease-in;
}

.faq.open .answer {
  opacity: 1;
  max-height: 100%;
  padding: 20px 0;
}

.faq .answer {
  // padding-left: 112px;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  // transition: all 0.4s ease-out;
}
</style>
