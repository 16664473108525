<template>
  <div class="vwCar">
    <div class="vwCar__content">
      <div class="vwCar__items">
        <div class="vwCar__items-blockTerms vwBlock">
          <div class="vwBlock__title">
            <h1>{{ $t("mobileHalalTitle.usloviya") }}</h1>
          </div>
          <div class="vwCar__items-blockTerms-content">
            <div
              v-for="(item, idx) in list1"
              :key="'1' + idx"
              class="vwCar__items-blockTerms-content-list"
            >
              <div class="vwCar__items-blockTerms-content-list-number">
                <p>{{ idx + 1 }}</p>
              </div>
              <div class="vwCar__items-blockTerms-content-list-apereance">
                <h2>{{ item.title }}</h2>
                <h3>{{ item.desc }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="vwBlock vwCar__items-blockCalculator">
          <div class="vwCalc">
            <div class="vwCalc__content">
              <div class="vwCalc__items">
                <div class="vwCalc__items-form">
                  <div class="vwCalc__items-form-price">
                    <div class="basicInputVw">
                      <h4 class="f-20">{{ $t("label.calculatorPrice") }}</h4>
                      <h5 class="f-14">{{ $t("label.maxCostCar") }}</h5>

                      <div class="showHideVw">
                        <h3>{{ $t("label.costCar") }}</h3>
                        <CurrencyInput
                          v-model="calculate.cost"
                          :min="0"
                          :max="carMaxSum"
                          :options="currencyOptions"
                          v-mask="'########'"
                          :class="{
                            invalid:
                              v$.calculate.cost.$dirty &&
                              v$.calculate.cost.$invalid,
                          }"
                        />
                        <span
                          ><svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.51172 17.5H5.5V6.85938H0.707031V4.62109H13.3047V6.85938H8.51172V17.5ZM13.3047 2.82812H0.707031V0.589844H13.3047V2.82812Z"
                              fill="#DDE2E5"
                            />
                          </svg>
                        </span>
                      </div>
                      <p
                        v-for="error of v$.calculate.cost.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </div>

                    <div class="basicInputVw">
                      <h4 class="f-20">{{ $t("label.firstPay") }}</h4>
                      <h5 class="f-14">{{ $t("label.minParcent") }}</h5>
                      <div class="showHideVw">
                        <h3>{{ $t("label.firstPay") }}</h3>
                        <CurrencyInput
                          v-model="calculate.initial"
                          :min="minDownPayment"
                          :max="calculate.cost"
                          v-mask="'########'"
                          @change="updatePrice"
                          :options="currencyOptions"
                        />
                        <span
                          ><svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.51172 17.5H5.5V6.85938H0.707031V4.62109H13.3047V6.85938H8.51172V17.5ZM13.3047 2.82812H0.707031V0.589844H13.3047V2.82812Z"
                              fill="#DDE2E5"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div class="basicInputVw">
                      <h4 class="f-20">{{ $t("label.toYear") }}</h4>
                      <div class="showHideVw">
                        <h3>{{ $t("label.rangePay") }}</h3>
                        <SelectComponent
                          class="buy-ticket__dropdown"
                          :selected="selectedParams"
                          :options="selectParams"
                          @select="selectOption"
                        >
                        </SelectComponent>
                      </div>
                    </div>
                    <div class="basicInputVw">
                      <h4 class="f-20">{{ $t("label.yourContacts") }}</h4>
                      <div class="showHideVw">
                        <h3>{{ $t("placeholder.name") }}</h3>
                        <input
                          v-model="calculate.name"
                          :class="{
                            invalid:
                              v$.calculate.name.$dirty &&
                              v$.calculate.name.$invalid,
                          }"
                        />
                      </div>
                      <p
                        v-for="error of v$.calculate.name.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                      <div class="showHideVw">
                        <h3>{{ $t("placeholder.phone") }}</h3>
                        <input
                          v-model="calculate.phone"
                          placeholder="+7"
                          :class="{
                            invalid:
                              v$.calculate.phone.$dirty &&
                              v$.calculate.phone.$invalid,
                          }"
                          v-mask="'+7 (###) ###-##-##'"
                        />
                      </div>
                      <p
                        v-for="error of v$.calculate.phone.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                      <div class="showHideVw">
                        <h3>{{ $t("placeholder.iin") }}</h3>
                        <input
                          v-model="calculate.iin"
                          v-mask="'############'"
                          :class="{
                            invalid:
                              v$.calculate.iin.$dirty &&
                              v$.calculate.iin.$invalid,
                          }"
                        />
                      </div>
                      <p
                        v-for="error of v$.calculate.iin.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                   
                      <div v-if="cities" class="showHideVw">
                        <h3>{{ $t("label.city") }}</h3>
                        <SelectComponent
                          class="buy-ticket__dropdown"
                          :selected="selectedCity"
                          :options="cities"
                          @select="selectOptionCity"
                        >
                        </SelectComponent>
        
                    </div>
                      <!-- <div class="showHideVw">
                        <h3>{{ $t("placeholder.address") }}</h3>
                        <input
                          v-model="calculate.address"
                          :class="{
                            invalid:
                              v$.calculate.address.$dirty &&
                              v$.calculate.address.$invalid,
                          }"
                        />
                      </div>
                      <p
                        v-for="error of v$.calculate.address.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vwBlock vwCar__items-blockSubmit">
          <div class="vwCalc__items-form-result">
            <div class="vwCalc__items-form-result-list">
              <div class="vwCalc__items-form-result-list-detail">
                <div class="titleText">
                  <span class="f-14">{{ $t("label.payHallal") }}</span>
                  <h4 class="f-20">{{ formatPrice(creditSum) }} ₸</h4>
                </div>
                <div class="titleText">
                  <span class="f-14">{{ $t("label.monthlyPayment") }}</span>
                  <h4 class="f-20">
                    {{ monthlyPayment ? formatPrice(monthlyPayment) : 0 }}
                    ₸
                    {{ $t("label.month") }}
                  </h4>
                </div>
              </div>

              <div class="vwCalc__items-form-result-list-btn" :class="disabled ? 'disabled' : ''">
                <button :disabled="disabled" @click="sendForm" class="f-18 btn_blue">
                  {{ $t("buttons.getPred") }}
                </button>
              </div>
              <div class="vwCalc__items-form-result-list-check">
                <div class="checkItemVw">
                  <div class="checkItemVw__items">
                    <div class="checkItemVw__items-text">
                      <span class="f-12"
                        >{{ $t("label.agreement") }}
                        <a @click="modalTextOpened = true">{{
                          $t("label.agreement1")
                        }}</a>
                        {{ $t("label.agreement2") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <swipe-modal
      v-model="successAlertOpened"
      contents-height="30vh"
      border-top-radius="20px"
      tip-color="#c8c8c8"
    >
    <modal-confirmation @close="successAlertOpened = false" :modalText="modalText" />
    </swipe-modal>
    <swipe-modal
      v-model="modalTextOpened"
      contents-height="80vh"
      border-top-radius="20px"
      tip-color="#c8c8c8"
    >
      <ModalText @close="modalTextOpened = false" :modalText="modalText2" />
    </swipe-modal>
  </div>
</template>

<script>
import SelectComponent from "@/components/SelectComponentVw";
import CurrencyInput from "@/components/CurrencyInput.vue";
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@/plugins/i18n-validators";
import { sameAs, maxValue } from "@vuelidate/validators";
import Tooltip from "@/components/tooltip.vue";
import modalv2Vue from "@/components/modal/modal.vue";
import ModalConfirmation from "@/components/modal/modalConfirmationMobile.vue";
import ModalText from "@/components/modal/modalTextMobile.vue";
import { useToast } from "vue-toastification";
import swipeModal from "@takuma-ru/vue-swipe-modal";
export default {
  components: {
    SelectComponent,
    CurrencyInput,
    Tooltip,
    modalv2Vue,
    ModalConfirmation,
    ModalText,
    swipeModal,
  },
  setup: () => ({ v$: useVuelidate(), toast: useToast() }),
  data() {
    return {
      list1: [
        {
          title: this.$t("mobileHalalTitle.fTItle1"),
          desc: this.$t("mobileHalalTitle.fdesc1"),
        },
        {
          title: this.$t("mobileHalalTitle.fTItle2"),
          desc: this.$t("mobileHalalTitle.fdesc2"),
        },
        {
          title: this.$t("mobileHalalTitle.fTItle3"),
          desc: this.$t("mobileHalalTitle.fdesc3"),
        },
        {
          title: this.$t("mobileHalalTitle.fTItle4"),
          desc: this.$t("mobileHalalTitle.fdesc4"),
        },
        {
          title: this.$t("mobileHalalTitle.fTItle5"),
          desc: this.$t("mobileHalalTitle.fdesc5"),
        },
      ],
      checked: false,
      selectParams: [
      {
          title: this.$t("selectParams.1"),
          parcent: 1,
          month: 12,
        },
        {
          title: this.$t("selectParams.2"),
          parcent: 2,
          month: 24,
        },
        {
          title: this.$t("selectParams.3"),
          parcent: 3,
          month: 36,
        },
        {
          title: this.$t("selectParams.4"),
          parcent: 4,
          month: 48,
        },
        {
          title: this.$t("selectParams.5"),
          parcent: 5,
          month: 60,
        },
        {
          title: this.$t("selectParams.6"),
          parcent: 6,
          month: 72,
        },
        {
          title: this.$t("selectParams.7"),
          parcent: 7,
          month: 84,
        },
      ],
      selectedParams: null,
      calculate: {
        cost: null,
        initial: null,
        name: null,
        phone: null,
        iin: null,
        address: null,
        checked: false,
      },
      creditSum: 0,
      creditMaxSum: 15000000,
      carMaxSum: 60000000,
      creditMaxParcentSum: 3000000,
      currencyOptions: {
        currency: "KZT",
        currencyDisplay: "hidden",
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: true,
        valueScaling: "precision",
        precision: 0,
        locale: "sv-SE"
      },
      modalText: {
        cTitle: this.$t("label.successReview"),
        success: true,
      },
      modalText2: {
        cText: this.$t("modal.agree"),
        success: false,
      },
      successAlertOpened: false,
      modalTextOpened: false,
      disabled: false,
      cities: null,
      selectedCity: null,
    };
  },
  validations: () => ({
    calculate: {
      phone: {
        required,
        minLength: minLength(18),
      },
      name: {
        required,
      },
      iin: {
        required,
        minLength: minLength(12),
      },
      // address: {
      //   required,
      // },
      cost: {
        required,
        // maxValue: maxValue(60000000)
      },
    },
  }),
  beforeCreate() {
    if (this.$route?.params?.lang) {
      this.$i18n.locale = this.$route?.params?.lang;
      localStorage.setItem("lang", this.$route?.params?.lang);
      this.$nextTick(this.$forceUpdate);
    }
  },
  created() {
    this.FETCH_CITIES();
    this.selectedParams = this.selectParams[0];
  },
  computed: {
    minDownPayment() {
      if (this.calculate.cost < this.creditMaxSum) {
        const result1 = this.calculate.cost * 0.2;
        return result1;
      } else {
        const result1 = this.calculate.cost * 0.2;
        const result2 = this.calculate.cost - this.creditMaxSum;
        const result3 = Math.max(result1, result2);
        return result3;
      }

      // return this.calculate.cost ? Math.round(this.calculate.cost * 0.2) : null;
    },
  },
  methods: {
    selectOption(option) {
      this.selectedParams = option;
      this.calculatePayment();
      this.$nextTick(this.$forceUpdate);
    },
    selectOptionCity(option) {
      this.selectedCity = option;
      this.$nextTick(this.$forceUpdate);
    },
    updateDownPayment() {
      if (this.calculate.cost < this.creditMaxSum) {
        const result1 = this.calculate.cost * 0.2;
        this.calculate.initial = result1;
        console.log("a");
      } else {
        const result1 = this.calculate.cost * 0.2;
        const result2 = this.calculate.cost - this.creditMaxSum;
        const result3 = Math.max(result1, result2);
        this.calculate.initial = result3;
        console.log("b");
      }
      this.calculatePayment();
      this.$nextTick(this.$forceUpdate);
    },
    updatePrice() {
      if (this.calculate.initial < this.minDownPayment) {
        this.calculate.initial = this.minDownPayment;
      }
      if (this.calculate.initial > this.calculate.cost) {
        this.calculate.initial = this.calculate.cost;
      } else {
      }
      this.calculatePayment();
      this.$nextTick(this.$forceUpdate);
    },
    calculatePayment() {
      this.creditSum = this.calculate.cost - this.calculate.initial;
      const principal = this.calculate.cost - this.calculate.initial;
      const rate = 0.36 / 12;
      const months = this.selectedParams.parcent * 12;
      const factor = Math.pow(1 + rate, months);
      const monthlyPayment = Math.round(
        (principal * factor * rate) / (factor - 1)
      );
      this.monthlyPayment = monthlyPayment;
      this.$nextTick(this.$forceUpdate);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    sendForm() {
      this.v$.calculate.$touch();
      if (!this.v$.calculate.$invalid) {
        this.disabled = true
              axios
                .post("V1/applications/add", {
                  name: this.calculate.name,
                  phone: "+" + this.calculate.phone.replace(/[^0-9]/g, ""),
                  iin: this.calculate.iin,
                  // address: this.calculate.address,
                  car_price: this.calculate.cost,
                  initial_payment: this.calculate.initial,
                  deadline: this.selectedParams.month,
                  total_price: this.creditSum,
                  month_price: this.monthlyPayment,
                  confirm: this.calculate.checked,
                  city_id: this.selectedCity.id
                },{
                  params: {
                    lang : this.$route?.params?.lang,
                  }
                })
                .then((response) => {
                  this.disabled = false
                  if (response.status === 200) {
                    console.log("response", response);
                    this.successAlertOpened = true;
                    this.clearForm()
                  } else {
                    console.log(response);
                  }
                })
                .catch((e) => {
                  this.disabled = false
                  this.toast.error(e.response?.data?.message);
                });
            } 
    },
    clearForm() {
      this.calculate.cost = "";
      this.calculate.name = "";
      this.calculate.phone = "";
      this.calculate.address = "";
      this.calculate.iin = "";
      this.v$.$reset();
    },
    FETCH_CITIES(){
      axios.get('V1/cities',{
                  params: {
                    lang : this.$route?.params?.lang,
                  }
                })
      .then(response => {
        this.cities = response.data.data;
        this.selectedCity = response.data.data[0]
      })
      .catch((e) => {
        console.log(e);
      })
    }
  },
  watch: {
    "calculate.cost"() {
      if (this.calculate.cost > this.carMaxSum) {
        this.calculate.cost = this.carMaxSum;
        this.$nextTick(this.$forceUpdate);
      }
      this.updateDownPayment();
    },
    modalTextOpened: function () {
      if (this.modalTextOpened) {
        document.body.style.overflow = "hidden";
      } else document.body.style.overflow = "auto";
    },
  },
};
</script>

<style lang="scss">
.swipe-modal-takumaru-vue-swipe-modal .modal-contents-chip {
  width: 86px!important;
  height: 5px!important;
  border-radius: 4px!important;
}
.swipe-modal-takumaru-vue-swipe-modal .modal-contents-chip-wrapper {
  padding-top: 16px!important;
  padding-bottom: 20px!important;
}

// element.style {
//     width: 100%;
//     min-height: 80vh;
//     border-top-left-radius: 20px;
//     border-top-right-radius: 20px;
//     background-color: white;
//     color: black;
//     bottom: 0px;
// }
.swipe-modal-takumaru-vue-swipe-modal .modal-contents {
  width: 95vw!important;
  // bottom: 10px!important;
  margin-bottom: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.disabled{
 button{
  background-color: gray!important;
 }
}
</style>
