<template>
  <div class="installment" v-if="HomeData?.banner">
    <div class="installmen__content container">
      <div class="installment__items">
        <div class="installment__items-title">
          <h1 class="f-72">{{ HomeData?.banner?.title }}</h1>
        </div>
        <div class="installment__items-desc">
          <h3 class="f-20">{{ HomeData?.banner?.description }}</h3>
        </div>
        <div style="position: relative" class="installment__items-list">
          <div
            v-for="(item, idx) in HomeData?.banner?.bannerItems"
            :key="idx"
            class="installment__items-list-item"
          >
            <h4 v-if="item.tooltip" class="f-20">
              <Popper arrow :class="theme" :content="item.tooltip">
                <button class="hover">{{ item.title }}</button>
              </Popper>
            </h4>
            <h4 v-else class="f-20">{{ item.title }}</h4>
            <p class="f-14">{{ item.description }}</p>
          </div>
        </div>
        <div class="installment__items-button">
          <button class="btn_black f-18" @click="scrollToSection('calculate')">
            {{ $t("buttons.getPrice") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "../tooltip.vue";
export default {
  props: ["HomeData"],
  components: {
    Tooltip,
  },
  data: () => ({}),

  methods: {
    scrollToSection(link) {
      this.$emit("scroll", link);
    },
  },
};
</script>

<style lang="scss" scoped>

:deep(.popper) {
    max-width: 300px;
    font-size: 16px;
    padding: 10px;
  }
  .hover{
    &:hover{
      text-decoration: underline;
    }
  }
</style>
