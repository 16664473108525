<template>
  <transition name="fade">
    <div class="vue-modal" v-show="open">
      <transition name="drop-in">
        <div class="vue-modal-inner" v-show="open">
          <div class="vue-modal-content">
            <div class="vue-modal-content-btnClose">
              <div class="close">
                <button type="button"></button>
                <button type="button" @click="close">
                  <span
                    ><svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.87548 23.5425L23.9604 8.45753M8.87548 8.45753L23.9604 23.5425L8.87548 8.45753Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <slot />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { onMounted, onUnmounted } from "vue";

export default {
  props: ["modalHead", "open"],
  setup(_, { emit }) {
    const close = () => {
      emit("close");
    };
    const handleKeyup = (event) => {
      if (event.keyCode === 27) {
        close();
      }
    };

    onMounted(() => document.addEventListener("keyup", handleKeyup));
    onUnmounted(() => document.removeEventListener("keyup", handleKeyup));
    return { close };
  },
  watch: {
    open: function () {
      if (this.open) {
        document.body.style.overflow = "hidden";
      } else document.body.style.overflow = "auto";
    },
  },
};
</script>

<style lang="scss" scoped>
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.close {
  top: 20px;
  left: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  //   padding: 0 0 5% 0;

  span {
    .f-16 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #000000;
    }
  }

  button {
    border: none;
    min-width: 32px;
    background: transparent;

    span {
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      display: flex;
      align-items: center;
      //   background: #ebecee;
      backdrop-filter: blur(20px);
      //   border-radius: 36px !important;
      //   padding: 11px;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.vue-modal {
  position: fixed;
  display: flex;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 998 !important;
  align-content: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: clamp(1100px, 40%,500px);
    // margin: 10px auto;

    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.13));
    // z-index: 10000000 !important;
  }

  &-content {
    touch-action: none;
    -ms-touch-action: none;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    padding: 20px 20px 20px 20px;
    // border-radius: 20px 20px 0px 0px;
    position: relative;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    // border-radius: 16px;

    @media (max-width: 576px) {
      width: 100vw;
      max-width: 100%;
      height: 100%;
      padding: 0 20px 20px 20px;
      // border-radius: 20px 20px 0px 0px;
      position: relative;
      overflow-y: auto;

      &-btnClose {
        @media (max-width: 576px) {
          border-radius: 0;
          position: relative;
        }
        // border-radius: 20px 20px 0px 0px;
        position: fixed;
        left: 0;
        right: 0;
        padding: 20px 16px 0 16px;
        background: #f5f6f6;
        z-index: 1;
        opacity: 0.98;
      }
    }

    background-clip: padding-box;
    background: #f5f6f6;
  }
}

//animation --->
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  // opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
  // opacity: 0;
  transform: translateX(100vh);
}

@media (max-width: 576px) {
  .vue-modal {
    &-inner {
      // width: clamp(100%, 40%, 500px);
    }
  }
}
</style>
