<template>
  <div class="confAlert">
    <div v-if="modalText" class="confAlert__items">
      <div class="confAlert__items-icon">
        <img v-if="modalText.success" src="@/assets/images/svg/Success2.svg" />
      </div>
      <div class="confAlert__items-title">
        <h2 class="f-20" v-html="modalText.cTitle"></h2>
      </div>
    </div>
    <button @click="$emit('close')" class="confButton">{{ $t("buttons.close") }}</button>
  </div>
</template>

<script>
export default {
  props: ["modalText"],
  data: () => ({}),
};
</script>

<style scoped lang="scss">
.confAlert {
  text-align: center;
  &__items {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    gap: 16px;
    &-title {
      margin: 0 40px 20px 40px;
      text-align: center;
    }
  }
  .confButton {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-bottom: 22px;
    color: #357bf6;
  }
}
</style>
