<template>
  <div class="tooltip_wrapper">
    <div>{{ word }}</div>
    <div class="tooltip_content">
      <slot></slot>
      <p v-html="text"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "word"],

  data: () => ({
    tolltip: true,
  }),
};
</script>

<style lang="scss">
.tooltip_wrapper {
  // position: absolute;
  // transform: translateX(100%);
  // // margin-right: -20px;
  // max-width: 360px;
  // right: 0;
  // left: 0;
  // //z-index: 1;
  &:hover {
    // width: 100%;
  }
  &:hover .tooltip_content {
    display: block;
    z-index: 10 !important;
  }
}

.tooltip_content {
  position: absolute;
  left: 40%;
  top: -270px;
  // border: 1px solid #fdde2b;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 28px 20px 40px;
  z-index: 9;
  font-size: 17px;
  max-width: 340px;
  // width: 100%;
  // transform: translateX(40px);
  display: none;

  img {
    max-width: 100%;
  }

  // &:after {
  //   content: "";
  //   width: 15px;
  //   height: 15px;
  //   // border-left: 1px solid #fdde2b;
  //   // border-bottom: 1px solid #fdde2b;
  //   background: #ffffff;
  //   // box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
  //   position: absolute;
  //   right: 0px;
  //   transform: translateX(-280px) rotate(-45deg);
  //   bottom: -9px;
  //   z-index: 9;
  // }
}

@media only screen and (max-width: 768px) {
  .tooltip_wrapper {
    transform: inherit !important;
    margin-right: 0 !important;
    max-width: 100% !important;
    width: auto !important;
    top: 20px;
    right: -50px;

    .tooltip_icon {
      right: 10px;
      position: absolute;
      //z-index: 50;
    }

    .tooltip_content {
      width: 280px !important;
      top: 40px;
      transform: translateX(-5px);
      padding: 14px 14px 20px !important;
      z-index: 100;

      // &::after {
      //   top: -7.5px;
      //   left: auto;
      //   right: 0;
      //   // border-left: 1px solid #fdde2b;
      //   // border-bottom: 1px solid transparent;
      //   // border-right: 1px solid transparent;
      //   // border-top: 1px solid #fdde2b;
      // }
    }
  }
}

@media only screen and (max-width: 576px) {
  .tooltip_wrapper {
    transform: inherit !important;
    margin-right: 0 !important;
    max-width: 100% !important;
    width: auto !important;
    top: 20px;
    right: 0;

    .tooltip_icon {
      right: 10px;
      position: absolute;
      //z-index: 50;
    }

    .tooltip_content {
      // width: 280px !important;
      top: 60px;
      transform: translateX(-5px);
      padding: 14px 14px 20px !important;
      z-index: 100;
      left: 16%;
      &::after {
        top: -7.5px;
        left: auto;
        right: 0;
        // border-left: 1px solid #fdde2b;
        // border-bottom: 1px solid transparent;
        // border-right: 1px solid transparent;
        // border-top: 1px solid #fdde2b;
        transform: translateX(-210px) rotate(45deg);
      }
    }
  }
}
</style>
