<template>
  <div class="home">
    <AboutInstallment @scroll="scrollTo" :HomeData="HomeData" />
    <BannerAbout :HomeData="HomeData" />
    <Calculator :HomeData="HomeData" />
    <Faq :HomeData="HomeData" />
  </div>
</template>

<script>
import AboutInstallment from "@/components/home/aboutInstallment.vue";
import BannerAbout from "@/components/home/bannerAbout.vue";
import Calculator from "@/components/home/calculator.vue";
import Faq from "@/components/home/faq.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    AboutInstallment,
    BannerAbout,
    Calculator,
    Faq,
  },
  data: () => ({
    HomeData: null,
  }),
  created() {
    this.FETCH_HOME();
  },
  methods: {
    FETCH_HOME() {
      axios
        .get("V1/page/home")
        .then((response) => {
          if (response.status === 200) {
            this.HomeData = response.data.data;
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    scrollTo(link) {
      const offsetTop = document.getElementById(link);
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>
