import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './assets/styles/main.css'
import "swiper/css";
import 'swiper/scss/navigation';
import "swiper/css/free-mode";
import "swiper/css/pagination";
import i18n from '@/plugins/i18n';
import axios from 'axios';
import VueTheMask from 'vue-the-mask';
import VueCookies from 'vue-cookies';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import vClickOutside from "click-outside-vue3"
import Popper from "vue3-popper";

const options = {
  position: "top-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

const lang = localStorage.getItem('lang') || 'kz';
axios.defaults.baseURL = 'https://backcar.tayyab.kz/api/';
axios.defaults.params = {
    'lang': lang,
  };


  createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueTheMask)
  .use(VueCookies, { expires: '1d'})
  .use(Toast, options)
  .use(vClickOutside)
  .component("Popper", Popper)
  .mount('#app')
  