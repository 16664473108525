<template>
  <div class="select">
    <div class="select__btn" @click="isVisible = !isVisible" ref="selectBtn">
      <div class="select__text uppercase">
        <div class="select__text-main">
          {{ selected.title }}
        </div>
        <span v-if="isVisible" class="">
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.7071 5.70711C9.3166 6.09763 8.6834 6.09763 8.2929 5.70711L5 2.4142L1.70711 5.70711C1.31658 6.09763 0.68342 6.09763 0.29289 5.70711C-0.09763 5.31658 -0.0976301 4.68342 0.29289 4.29289L4.29289 0.2929C4.68342 -0.0976001 5.3166 -0.0976002 5.7071 0.2929L9.7071 4.29289C10.0976 4.68342 10.0976 5.31658 9.7071 5.70711Z"
              fill="#77757F"
            />
          </svg>
        </span>
        <span v-else>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.2929 0.29289C0.6834 -0.0976304 1.3166 -0.0976304 1.7071 0.29289L5 3.5858L8.29289 0.29289C8.68342 -0.0976301 9.31658 -0.09763 9.70711 0.29289C10.0976 0.68342 10.0976 1.31658 9.70711 1.70711L5.70711 5.7071C5.31658 6.0976 4.6834 6.0976 4.2929 5.7071L0.2929 1.70711C-0.0976 1.31658 -0.0976 0.68342 0.2929 0.29289Z"
              fill="#77757F"
            />
          </svg>
        </span>
      </div>
    </div>
    <transition name="fade">
      <ul class="select__list uppercase" v-if="isVisible">
        <li
          v-for="option in options"
          :key="option.value"
          @click="selectOption(option)"
        >
          {{ option.title }}
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
export default {
  props: ["options", "selected"],
  data() {
    return {
      isVisible: false,
      cdn: null,
    };
  },
  methods: {
    selectOption(option) {
      this.$emit("select", option);
      this.isVisible = false;
      // this.$router.push(`/product/${option.slug}`)
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  position: relative;
  

  &__btn {
    display: flex;
    align-items: center;
    padding: 12px 40px 12px 20px;
    background: transparent;
    border: 1px solid #c7d2da;
    border-radius: 30px;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-main {
      display: flex;
      align-items: center;
      gap: 5px;
      text-align: left;
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
      }
    }
  }

  &__arrow {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &__list {
    border-radius: 10px;
    position: absolute;
    border: 1px solid #cfcfcf;
    cursor: pointer;
    background-color: #ffffff;
    min-width: 226px;
    margin: 0;
    max-height: 500px;
    overflow-x: auto;
    width: 100%;
    z-index: 12;
    li {
      display: flex;
      align-items: center;
      padding: 7px 12px;
      justify-content: flex-start;
      gap: 5px;
      text-align: left;
      &:hover,&:active{
        background-color: #F5F6FA;
      }
    }

    span {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
