<template>
  <div class="calculator" id="calculate">
    <div class="calculator__content container">
      <div class="calculator__items">
        <div class="calculator__items-title">
          <h1 class="f-48">{{ $t("label.calculatorPrice") }}</h1>
          <h5 style="position: relative" class="f-14">
            {{ $t("label.calculatorPriceDesc") }}
            <Popper arrow :class="theme" :content="$t('label.calculatorPriceDesc3')">
              <button class="hover">{{ $t('label.calculatorPriceDesc2') }}</button>
            </Popper>
            {{ $t("label.calculatorPriceDesc1") }}
          </h5>
        </div>
        <div class="calculator__items-form">
          <div class="calculator__items-form-price">
            <div class="basicInput">
              <h4 class="f-20 ">{{ $t("label.costCar") }}</h4>
              <h5 class="f-14 m_none">{{ $t("label.maxCostCar") }}</h5>
              <h5 class="f-14 m_none">
                {{ $t("label.maxFinanceCar") }}
              </h5>
              <h5 class="f-14 d_none">{{ $t("label.maxCostCarMob") }}</h5>
              <h5 class="f-14 d_none">
                {{ $t("label.maxFinanceCarMob") }}
              </h5>
              <div class="showHide">
                <!-- <input
                  v-model="calculate.cost"
                  @input="updateDownPayment"
                  :min="0"
                  :max="carMaxSum"
                /> -->
                <CurrencyInput v-model="calculate.cost" :min="0" :max="carMaxSum" :options="currencyOptions"
                  v-mask="'########'" :class="{
                    invalid:
                      v$.calculate.cost.$dirty && v$.calculate.cost.$invalid,
                  }" />
              </div>
              <p v-for="error of v$.calculate.cost.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>

            <div class="basicInput">
              <h4 class="f-20">{{ $t("label.firstPay") }}</h4>
              <h5 class="f-14">{{ $t("label.minParcent") }}</h5>
              <div class="showHide">
                <!-- <input
                  v-model="calculate.initial"
                  @change="updatePrice"
                  :min="minDownPayment"
                  :max="calculate.cost"
                  v-mask="'########'"
                  type="tel"
                /> -->
                <CurrencyInput v-model="calculate.initial" :min="minDownPayment" :max="calculate.cost" v-mask="'########'"
                  @change="updatePrice" :options="currencyOptions" />
              </div>
            </div>
            <div class="basicInput">
              <h4 class="f-20">{{ $t("label.rangePay") }}</h4>
              <h5 class="f-14">{{ $t("label.yearPayment") }}</h5>
              <div class="showHide">
                <SelectComponent class="buy-ticket__dropdown" :selected="selectedParams" :options="selectParams"
                  @select="selectOption">
                </SelectComponent>
              </div>
            </div>
            <div class="basicInput">
              <h4 class="f-20">{{ $t("label.yourContacts") }}</h4>
              <div class="showHide">
                <input v-model="calculate.name" :placeholder="$t('placeholder.name')" :class="{
                  invalid:
                    v$.calculate.name.$dirty && v$.calculate.name.$invalid,
                }" />
              </div>
              <p v-for="error of v$.calculate.name.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
              <div class="showHide">
                <input v-model="calculate.phone" :placeholder="$t('placeholder.phone')" :class="{
                  invalid:
                    v$.calculate.phone.$dirty && v$.calculate.phone.$invalid,
                }" v-mask="'+7 (###) ###-##-##'" />
              </div>
              <p v-for="error of v$.calculate.phone.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
              <div class="showHide">
                <input v-model="calculate.iin" v-mask="'############'" :placeholder="$t('placeholder.iin')" :class="{
                  invalid:
                    v$.calculate.iin.$dirty && v$.calculate.iin.$invalid,
                }" />
              </div>
              <p v-for="error of v$.calculate.iin.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
              <div v-if="cities" class="showHide">
                <SelectComponent class="buy-ticket__dropdown" :selected="selectedCity" :options="cities"
                  @select="selectOptionCity">
                </SelectComponent>
              </div>
              <!-- <div class="showHide">
                <input
                  v-model="calculate.address"
                  :placeholder="$t('placeholder.address')"
                  :class="{
                    invalid:
                      v$.calculate.address.$dirty &&
                      v$.calculate.address.$invalid,
                  }"
                />
              </div>
              <p
                v-for="error of v$.calculate.address.$errors"
                :key="error.$uid"
              >
                {{ error.$message }}
              </p> -->
            </div>
          </div>
          <div class="calculator__items-form-result">
            <div class="calculator__items-form-result-list">
              <div class="calculator__items-form-result-list-detail">
                <div class="titleText">
                  <span class="f-14">{{ $t("label.payHallal") }}</span>
                  <p class="f-14">{{ $t("label.maxPlan") }}</p>
                  <h4 class="f-20">{{ formatPrice(creditSum) }} ₸</h4>
                </div>
                <div class="titleText">
                  <span class="f-14">{{ $t("label.monthlyPayment") }}</span>
                  <h4 class="f-20">
                    {{ monthlyPayment ? formatPrice(monthlyPayment) : 0 }} ₸
                    {{ $t("label.month") }}
                  </h4>

                  <h5 style="position: relative; margin-top: 5px;" class="f-14">
                    {{ $t("torgNad1") }}
                    <Popper arrow :class="theme" :content="$t('label.calculatorPriceDesc3')">
                      <button class="hover"> {{ $t("torgNad2") }}</button>
                    </Popper>
                    {{ $t("torgNad3") }}
                  </h5>
                </div>
              </div>
              <div class="calculator__items-form-result-list-check">
                <div class="checkItem">
                  <div class="checkItem__items">
                    <div class="checkItem__items-accept">
                      <input :class="{
                        invalid:
                          v$.calculate.checked.$dirty &&
                          v$.calculate.checked.$invalid,
                      }" type="checkbox" id="checkbox" v-model="calculate.checked" />
                    </div>
                    <div class="checkItem__items-text">
                      <span class="f-12">{{ $t("label.agreement") }} <a style="text-decoration: underline;"
                          @click="modalTextOpened = true">{{ $t("label.agreement1") }}</a> {{ $t("label.agreement2")
                          }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="calculator__items-form-result-list-btn">
                <button @click="sendForm" class="f-18 btn_black">
                  {{ $t("buttons.getPred") }}
                </button>
              </div>
              <div class="calculator__items-form-result-list-info">
                <p class="f-12">
                  {{ $t("label.paymentArea") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalv2Vue @close="successAlertOpened = false" :open="successAlertOpened">
      <modal-confirmation :modalText="modalText" />
    </modalv2Vue>
    <modalv2Vue @close="modalTextOpened = false" :open="modalTextOpened">
      <ModalText :modalText="modalText2" />
    </modalv2Vue>
  </div>
</template>

<script>
import SelectComponent from "@/components/SelectComponent";
import Asdasd from "./asdasd.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@/plugins/i18n-validators";
import { sameAs, maxValue } from "@vuelidate/validators";
import Tooltip from "../tooltip.vue";
import modalv2Vue from "@/components/modal/modal.vue";
import ModalConfirmation from "@/components/modal/modalConfirmation.vue";
import ModalText from "@/components/modal/modalText.vue";
import { useToast } from "vue-toastification";
export default {
  components: {
    SelectComponent,
    Asdasd,
    CurrencyInput,
    Tooltip,
    modalv2Vue,
    ModalConfirmation,
    ModalText

  },
  setup: () => ({ v$: useVuelidate(), toast: useToast() }),
  data() {
    return {
      checked: false,
      selectParams: [
        {
          title: this.$t("selectParams.1"),
          parcent: 1,
          month: 12,
        },
        {
          title: this.$t("selectParams.2"),
          parcent: 2,
          month: 24,
        },
        {
          title: this.$t("selectParams.3"),
          parcent: 3,
          month: 36,
        },
        {
          title: this.$t("selectParams.4"),
          parcent: 4,
          month: 48,
        },
        {
          title: this.$t("selectParams.5"),
          parcent: 5,
          month: 60,
        },
        {
          title: this.$t("selectParams.6"),
          parcent: 6,
          month: 72,
        },
        {
          title: this.$t("selectParams.7"),
          parcent: 7,
          month: 84,
        },
      ],
      selectedParams: null,
      calculate: {
        cost: null,
        initial: null,
        name: null,
        phone: null,
        iin: null,
        address: null,
        checked: false,
      },
      creditSum: 0,
      creditMaxSum: 15000000,
      carMaxSum: 60000000,
      creditMaxParcentSum: 3000000,
      currencyOptions: {
        currency: "KZT",
        currencyDisplay: "narrowSymbol",
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: true,
        valueScaling: "precision",
        precision: 0,
        locale: "sv-SE"
      },
      modalText: {
        cTitle: this.$t("label.successReview"),
        success: true,
      },
      modalText2: {
        cText: this.$t("publicOffer"),
        success: false,
      },
      successAlertOpened: false,
      modalTextOpened: false,
      cities: null,
      selectedCity: null,
    };
  },
  validations: () => ({
    calculate: {
      phone: {
        required,
        minLength: minLength(18),
      },
      name: {
        required,
      },
      iin: {
        required,
        minLength: minLength(12),
      },
      // address: {
      //   required,
      // },
      cost: {
        required,
        // maxValue: maxValue(60000000)
      },
      checked: {
        required,
        sameAs: sameAs(true),
      },
    },
  }),
  created() {
    this.FETCH_CITIES();
    this.selectedParams = this.selectParams[0];

  },
  computed: {
    minDownPayment() {
      if (this.calculate.cost < this.creditMaxSum) {
        const result1 = this.calculate.cost * 0.2;
        return result1;
      } else {
        const result1 = this.calculate.cost * 0.2;
        const result2 = this.calculate.cost - this.creditMaxSum;
        const result3 = Math.max(result1, result2);
        return result3;
      }

      // return this.calculate.cost ? Math.round(this.calculate.cost * 0.2) : null;
    },
  },
  methods: {
    formatPrice: (price) => `${(+price).toLocaleString()} ₸`,
    selectOption(option) {
      this.selectedParams = option;
      this.calculatePayment();
      this.$nextTick(this.$forceUpdate);
    },
    selectOptionCity(option) {
      this.selectedCity = option;
      this.$nextTick(this.$forceUpdate);
    },
    updateDownPayment() {
      if (this.calculate.cost < this.creditMaxSum) {
        const result1 = this.calculate.cost * 0.2;
        this.calculate.initial = result1;
        console.log("a");
      } else {
        const result1 = this.calculate.cost * 0.2;
        const result2 = this.calculate.cost - this.creditMaxSum;
        const result3 = Math.max(result1, result2);
        this.calculate.initial = result3;
        console.log("b");
      }
      this.calculatePayment();
      this.$nextTick(this.$forceUpdate);
    },
    updatePrice() {
      if (this.calculate.initial < this.minDownPayment) {
        this.calculate.initial = this.minDownPayment;
      }
      if (this.calculate.initial > this.calculate.cost) {
        this.calculate.initial = this.calculate.cost;
      } else {
      }
      this.calculatePayment();
      this.$nextTick(this.$forceUpdate);
    },
    calculatePayment() {
      this.creditSum = this.calculate.cost - this.calculate.initial;
      const principal = this.calculate.cost - this.calculate.initial;
      const rate = 0.36 / 12;
      const months = this.selectedParams.parcent * 12;
      const factor = Math.pow(1 + rate, months);
      const monthlyPayment = Math.round(
        (principal * factor * rate) / (factor - 1)
      );
      this.monthlyPayment = monthlyPayment;
      this.$nextTick(this.$forceUpdate);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    sendForm() {
      this.v$.calculate.$touch();
      if (!this.v$.calculate.$invalid) {
        const formData = new FormData();
        formData.append("iin", this.calculate.iin);
        formData.append("name", this.calculate.name);
        formData.append("phone", ("+" + this.calculate.phone.replace(/[^0-9]/g, "")));
        formData.append("payment[amount]", this.calculate.cost);
        formData.append("payment[term]", this.selectedParams.parcent);
        formData.append("payment[downpayment]", this.calculate.initial);
        // formData.append("reg_address[region]", this.calculate.address);
        formData.append("formid", "41");
        formData.append("formname", "Cart");

        axios
          .post("V1/applications/add", {
            name: this.calculate.name,
            phone: "+" + this.calculate.phone.replace(/[^0-9]/g, ""),
            iin: this.calculate.iin,
            // address: this.calculate.address,
            car_price: this.calculate.cost,
            initial_payment: this.calculate.initial,
            deadline: this.selectedParams.month,
            total_price: this.creditSum,
            month_price: this.monthlyPayment,
            confirm: this.calculate.checked,
            city_id: this.selectedCity.id
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("response", response);
              this.successAlertOpened = true;
              this.clearForm()
            } else {
              console.log(response);
            }
          })
          .catch((e) => {
            this.toast.error(e.response?.data?.message);
          });
      }
    },
    clearForm() {
      this.calculate.cost = '';
      this.calculate.name = '';
      this.calculate.phone = '';
      this.calculate.address = '';
      this.calculate.iin = '';
      this.v$.$reset()
    },
    FETCH_CITIES() {
      axios.get('V1/cities')
        .then(response => {
          this.cities = response.data.data;
          this.selectedCity = response.data.data[0]
        })
        .catch((e) => {
          console.log(e);
        })
    }
  },
  watch: {
    "calculate.cost"() {
      if (this.calculate.cost > this.carMaxSum) {
        this.calculate.cost = this.carMaxSum;
        this.$nextTick(this.$forceUpdate);
      }
      this.updateDownPayment();
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.popper) {
  max-width: 300px;
  font-size: 16px;
  padding: 10px;
}

.hover {
  &:hover {
    text-decoration: underline;
  }
}
</style>
