import { createStore } from 'vuex'

export default createStore({
  state: {
    toggleNav: false,
  },
  getters: {
    GET_TOGGLE_NAV: (state) => state.toggleNav,
  },
  mutations: {
    TOGGLE_MOBILE_NAV(state) {
      state.toggleNav = !state.toggleNav
    },
  },
  actions: {
  },
  modules: {
  }
})
